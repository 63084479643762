// ** Auth Endpoints
export default {
  loginEndpoint: 'https://api.nextniro.com/api/v1/auth/tasa/login',
  registerEndpoint: 'https://api.nextniro.com/api/v1/auth/register',
  refreshEndpoint: 'https://api.nextniro.com/api/v1/auth/tasa/refresh',
  logoutEndpoint: 'https://api.nextniro.com/api/v1/auth/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}

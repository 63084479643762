import axios, {AxiosPromise}  from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        let accessToken = this.getToken()

        console.log('valor de accessToken: ', accessToken)

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          accessToken.replace(/['"]+/g, '')

          accessToken = accessToken.replace(/^"(.*)"$/, '$1')
          console.log('valor de accessToken después: ', accessToken)
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
/*  axios.interceptors.response.use(
        response => response,
        error => {
          // ** const { config, response: { status } } = error
          const { config, response } = error
          console.log('valor de config en interceptors: ', config)
          console.log('valor de response en interceptors: ', response)
          const originalRequest = config
          console.log('originalRequest en interceptor: ', originalRequest)
  
          // ** if (status === 401) {
          if (response && response.status === 401) {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true
              this.refreshToken().then(r => {
                this.isAlreadyFetchingAccessToken = false
  
                let accessToken = r.data.accessToken
                let refreshToken = r.data.refreshToken
  
                console.log('valores de accessToken en antes responde 401: ', accessToken)
                console.log('valores de accessToken en antes responde 401: ', refreshToken)
  
                accessToken = accessToken.replace(/^"(.*)"$/, '$1')
                refreshToken = refreshToken.replace(/^"(.*)"$/, '$1')
  
                console.log('valores de accessToken en responde 401: ', accessToken)
                console.log('valores de accessToken en responde 401: ', refreshToken)
  
                // ** Update accessToken in localStorage
                this.setToken(accessToken)
                this.setRefreshToken(refreshToken)
                this.onAccessTokenFetched(accessToken)
              })
            }
            const retryOriginalRequest = new Promise(resolve => {
              this.addSubscriber(accessToken => {
                // ** Make sure to assign accessToken according to your response.
                // ** Check: https://pixinvent.ticksy.com/ticket/2413870
                // ** Change Authorization header
                console.log('originalRequest en retryOriginalRequest: ', originalRequest)
                originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                resolve(axios(originalRequest))
              })
            })
            return retryOriginalRequest
          } 
          return Promise.reject(error)
        }
      )
*/
      axios.interceptors.response.use(
        response => {
          console.log('valor de response en interceptor: ', response)
          if (typeof response.data === 'string') {
            response.data = JSON.parse(response.data)
          }
          return response
        },
        error => {
          // ** const { config, response: { status } } = error
          const { config, response } = error
          console.log('valor de config en interceptors: ', config)
          console.log('valor de response en interceptors: ', response)
          const originalRequest = config
          console.log('originalRequest en interceptor: ', originalRequest)
  
          // ** if (status === 401) {
          if (response && response.status === 401) {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true
              this.refreshToken().then(r => {
                this.isAlreadyFetchingAccessToken = false
  
                let accessToken = r.data.accessToken
                let refreshToken = r.data.refreshToken
  
                console.log('valores de accessToken en antes responde 401: ', accessToken)
                console.log('valores de accessToken en antes responde 401: ', refreshToken)
  
                accessToken = accessToken.replace(/^"(.*)"$/, '$1')
                refreshToken = refreshToken.replace(/^"(.*)"$/, '$1')
  
                console.log('valores de accessToken en responde 401: ', accessToken)
                console.log('valores de accessToken en responde 401: ', refreshToken)
  
                // ** Update accessToken in localStorage
                this.setToken(accessToken)
                this.setRefreshToken(refreshToken)
                this.onAccessTokenFetched(accessToken)
              })
            }
            const retryOriginalRequest = new Promise(resolve => {
              this.addSubscriber(accessToken => {
                // ** Make sure to assign accessToken according to your response.
                // ** Check: https://pixinvent.ticksy.com/ticket/2413870
                // ** Change Authorization header
                console.log('originalRequest en retryOriginalRequest: ', originalRequest)
                originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                resolve(axios(originalRequest))
              })
            })
            return retryOriginalRequest
          } 
          return Promise.reject(error)
        }
      )


/*
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalConfig = error?.config

        const originalRequest = config
        console.log("1. error en interceptors response ----> ", error)
        console.log("1. config en interceptors error.response ----> ", error.response)
        console.log("1. config en interceptots ------> ", originalConfig)
        console.log('2. valor de config en interceptors: ', config)
        console.log('2. valor de response en interceptors: ', response)
        console.log('2. originalRequest en interceptor: ', originalRequest)

        if (!error?.response) {

          console.log('ENTRA EN !ERROR.response no hay red')

        } else if (originalConfig.url !== "https://api.nextniro.com/api/v1/auth/tasa/login" && error.response) {

          console.log('ENTRA EN ERROR.response 401 403 405 503')
          if (response && response.status === 401) {

            originalConfig._retry = true
            this.refreshToken().then(r => {
              console.log('valor de respuesta r: ', r)

              let accessToken = r.data.accessToken
              let refreshToken = r.data.refreshToken

              console.log('valores de accessToken en antes responde 401: ', accessToken)
              console.log('valores de accessToken en antes responde 401: ', refreshToken)

              accessToken = accessToken.replace(/^"(.*)"$/, '$1')
              refreshToken = refreshToken.replace(/^"(.*)"$/, '$1')

              console.log('valores de accessToken en responde 401: ', accessToken)
              console.log('valores de accessToken en responde 401: ', refreshToken)

              // ** Update accessToken in localStorage
              this.setToken(accessToken)
              this.setRefreshToken(refreshToken)
              this.onAccessTokenFetched(accessToken)
              console.log('originalRequest en retryOriginalRequest: ', originalRequest)
              originalRequest._retry = true
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`

              return axios(originalRequest)
            }
            )
          }

          return Promise.reject(error)
        }
      }
    ) */
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    console.log('valor de value en setToken', value)
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    let refreshTokenLocal = this.getRefreshToken()
    refreshTokenLocal = refreshTokenLocal.replace(/^"(.*)"$/, '$1')
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: refreshTokenLocal
    })
  }
}
